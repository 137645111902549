import React, { useState } from 'react';
import { videoApi } from '../../../api/videoApi.js';
import { NavBar } from '../../app/navBar.js';

const ThumbnailUploadScreen = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [videoID, setVideoID] = useState(""); // State for video ID

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onIDChange = (event) => {
        setVideoID(event.target.value);
    };

    const onUpload = async () => {
        if (!videoID) {
            displayBanner("Please specify a Video ID.", "red");
            return;
        }

        if (selectedFile) {
            // Read the file as a base64 string
            const reader = new FileReader();
            reader.onload = async () => {
                const base64String = reader.result.split(',')[1];

                // Prepare JSON payload
                const payload = {
                    videoID,
                    document: base64String, // Base64-encoded file
                    filename: selectedFile.name, // Optionally include the filename
                };

                try {
                    const response = await videoApi.uploadThumbnail(payload);

                    if (response.status === true) {
                        displayBanner("Success", "green");
                    } else {
                        displayBanner("Failure", "red");
                    }
                } catch (error) {
                    console.error("Error saving the video thumbnail:", error);
                    displayBanner("Failure", "red");
                }
            };

            reader.onerror = () => {
                displayBanner("Failed to read the file.", "red");
            };

            reader.readAsDataURL(selectedFile); // Convert file to Base64
        } else {
            displayBanner("Please select a file to upload.", "red");
        }
    };

    const onDrop = (e) => {
        e.preventDefault();
        let file = e.dataTransfer.files[0];
        setSelectedFile(file);
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const displayBanner = (message, type) => {
        const banner = document.createElement("div");
        banner.textContent = message;
        banner.classList.add("banner");

        if (type === "green") {
            banner.classList.add("success");
        } else if (type === "red") {
            banner.classList.add("failure");
        }

        document.body.appendChild(banner);

        setTimeout(() => {
            banner.style.opacity = "0";
            setTimeout(() => {
                banner.remove();
            }, 500);
        }, 2000);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <NavBar />
            <h1>Upload Thumbnail</h1>

            {/* Input field for Video ID */}
            <input
                type="text"
                placeholder="Enter Video ID"
                value={videoID}
                onChange={onIDChange}
                style={{
                    padding: '10px',
                    fontSize: '16px',
                    width: '300px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                }}
            />

            {/* Drag-and-drop area */}
            <div
                onDrop={onDrop}
                onDragOver={onDragOver}
                style={{ border: '2px dashed #ccc', padding: '50px', width: '500px', textAlign: 'center' }}
            >
                {selectedFile ? selectedFile.name : 'Drag & Drop a file here or select below'}
            </div>

            {/* File input */}
            <input type="file" onChange={onFileChange} />

            {/* Upload button */}
            {selectedFile && (
                <button onClick={onUpload} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
                    Upload
                </button>
            )}
        </div>
    );
};

export { ThumbnailUploadScreen };