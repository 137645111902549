import { ooFetchWithResponse } from './ooFetchWithResponse';

let statApi = {
    getLocations: async (sort, limit) => {
        let url = 'stats/v1/locations?sort=' + sort + '&limit=' + limit;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getLocationID: async (location) => {
        let url = 'stats/v1/locationCorrect?location=' + location;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
}

export { statApi };