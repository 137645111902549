import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { statApi } from '../../../api/statApi.js';
import { NavBar } from '../../app/navBar.js';
import './statScreen.css';  // Import the CSS file

function StatScreen() {
    const [topLocations, setTopLocations] = useState([]);
    const [bottomLocations, setBottomLocations] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const topResponse = await statApi.getLocations("DESC", 10);
                const bottomResponse = await statApi.getLocations("ASC", 10);
                setTopLocations(topResponse.data.rows);
                setBottomLocations(bottomResponse.data.rows);
            } catch (err) {
                setError(err.message || "An error occurred.");
            }
        })();
    }, []);

    const handleLocationClick = async (location) => {
        try {
            const response = await statApi.getLocationID(location);
            const imageID = response.data.id;

            // Check if the imageID is valid
            if (imageID === 0 || imageID === -1) {
                setError("Invalid location ID. Unable to navigate.");
            } else {
                // Navigate to the details page with the image ID
                navigate(`/details/${imageID}`);
            }
        } catch (err) {
            setError("An error occurred while fetching the location ID.");
        }
    };

    return (
        <div className="stat-screen">
            <NavBar />
            <h2 className="stat-title">Stats</h2>

            {error && <p className="error-message">{error}</p>}

            <div className="locations-container">
                {/* Top Locations */}
                <div className="locations-box">
                    <h3 className="locations-title">Top Locations</h3>
                    <ul className="locations-list">
                        {Array.isArray(topLocations) && topLocations.map((location, index) => (
                            <li key={index} className="location-item">
                                <div className="location-box">
                                    {location.location} - <span className="count">{location.count}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Bottom Locations */}
                <div className="locations-box">
                    <h3 className="locations-title">Bottom Locations</h3>
                    <ul className="locations-list">
                        {Array.isArray(bottomLocations) && bottomLocations.map((location, index) => (
                            <li key={index} className="location-item">
                                <div
                                    className="location-box"
                                    onClick={() => handleLocationClick(location.location)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {location.location} <span className="count">{location.count}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export { StatScreen };
