import { ooFetchWithResponse } from './ooFetchWithResponse';

let videoApi = {
    editVideoDetails: async (videoID, params) => {
        let url = 'videos/v1/' + videoID + '/details';
        let body = JSON.stringify({ params });
        let response = await ooFetchWithResponse(url, { method: "PUT", body: body });
        return response;
    },
    getVideoDetails: async (videoID) => {
        let url = 'videos/v1/' + videoID + '/details';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getVideos: async (page = 1, pageSize = 50) => {
        let url = `videos/v1?page=${page}&pageSize=${pageSize}`;
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    getVideoURL: async (videoID, shareToken=null) => {
        let url = 'videos/v1/' + videoID;
        if(shareToken !== null) {
            url += "?share=" + shareToken;
        }
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    shareVideo: async (videoID) => {
        let url = 'videos/v1/' + videoID + '/share';
        let response = await ooFetchWithResponse(url, {
            method: 'GET',
        });
        return response;
    },
    uploadThumbnail: async (data) => {
        let url = 'thumbnail/v1';
        let body = JSON.stringify({ data });
        let response = await ooFetchWithResponse(url, { method: "POST", body: body });
        return response;
    },

}

export { videoApi };