import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Login } from '../../components';
import { OtherMenu } from '../../components/app/otherMenu.js';
import { HomeScreen, UploadScreen, ViewDetailsScreen, ImageModal, AlbumSelect, AlbumScreenV2,
    SearchDateScreen, VideoSelect, WatchVideo, RandomScreen, CreateNoteScreen, NoteSelect, ViewNoteDetails, ThumbnailUploadScreen, StatScreen } from '../../components/usr';

function App() {
    return (
        <div className="App">
            <div className="content">
                <Routes>
                    <Route path="/" element={<HomeScreen />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/home" element={<HomeScreen />} />
                    <Route path="/upload" element={<UploadScreen />} />
                    <Route path="/details/:imageID" element={<ViewDetailsScreen key={window.location.pathname}/>} />
                    <Route path="/albums" element={<AlbumSelect/>} />
                    <Route path="/albums/:albumID" element={<AlbumScreenV2 key={window.location.pathname}/>} />
                    <Route path="/albums/:albumID/:imageID" element={<ImageModal key={window.location.pathname}/>} />
                    <Route path="/notes" element={<NoteSelect/>} />
                    <Route path="/other" element={<OtherMenu />} />
                    <Route path="/random" element={<RandomScreen />} />
                    <Route path="/search" element={<SearchDateScreen/>} />
                    <Route path="/stats" element={<StatScreen/>} />
                    <Route path="/videos" element={<VideoSelect/>} />
                    <Route path="/videos/:videoID" element={<WatchVideo/>} />
                    <Route path="/create-note" element={<CreateNoteScreen />} />
                    <Route path="/view-note/:noteId" element={<ViewNoteDetails />} />
                    <Route path="/videos/thumbnail-upload" element={<ThumbnailUploadScreen />} />
                </Routes>
            </div>
        </div>
    )
}

export default App;
